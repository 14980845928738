<template>
  <div>
    <div
      v-if="isSupportScreenshotShowing"
      class="supportBlocker"
      @click="RetateScreenshotClicked"
    ></div>
    <div class="popUpToShowScreenshotOfSupport w-9/12">
      <canvas
        v-if="!isSupportScreenshotShowing"
        id="canvasOfSupport"
        width="300"
        height="100"
        class="hidden"
      ></canvas>
      <!-- <div> -->
      <!-- send mail modal code starts -->
      <div
        class="sendMail_modal h-full"
        v-show="isSupportScreenshotShowing"
        @click="outSideClicked"
      >
        <div class="Child_Of_sendMail_modal w-9/12" style="height: 85%">
          <div
            class="p-4 py-2 flex justify-between items-center shadow"
            style="height: 42px"
          >
            <div class="flex justify-around">
              <button
                style="width: 84px"
                class="text-xs p-1 mr-2 rounded"
                :class="{ activeCommuni: selectedOne === 1 }"
                @click="clickedOnCommunication(1)"
              >
                Email
              </button>
              <button
                style="width: 84px"
                class="text-xs p-1 mr-2 rounded"
                :class="{ activeCommuni: selectedOne === 2 }"
                @click="clickedOnCommunication(2)"
              >
                SMS
              </button>
              <button
                style="width: 84px"
                class="transition duration-500 ease-in-out text-xs p-1 rounded"
                :class="{ activeCommuni: selectedOne === 3 }"
                @click="clickedOnCommunication(3)"
              >
                Both
              </button>
            </div>
            <button
              @click="closehandler('fromElement')"
              class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 50px)"
          >
            <div style="width: 40%" class="emailSubmit_Form">
              <form @submit.prevent="sendEmail" class="flex flex-col">
                <div>
                  <label
                    for="selectSubEntity"
                    class="text-xs fontWeight-600 mb-1"
                    >Choose Sub-Entity</label
                  >
                  <multi-select
                    class="sub__"
                    @getUsersBasedOnselection="opeListSub(1)"
                    :sendClicked="mail_Sending"
                    :allUsersSelected="allUsersSelected1"
                    :reqOptions="subEntities"
                    :selectedOptions="selectedOpSub"
                    @getingSelectedOptions="selectedSubentities"
                    @onCrossClicked="onCrossClickedOnSubEntities"
                    :selectedAll="'Sub-Entities'"
                    :showAllMailsFromParent="showAllMailsFromParent1"
                    :dummy="['salman1', 'salman2']"
                    :key="refreshSelectMenu"
                  />
                </div>
                <div>
                  <label for="chooseRT" class="text-xs fontWeight-600 mb-1"
                    >Choose Role Type</label
                  >
                  <multi-select
                    class="role__"
                    :sendClicked="mail_Sending"
                    :allUsersSelected="allUsersSelected2"
                    :reqOptions="roleType"
                    @getingSelectedOptions="selectedRoleTypes"
                    @onCrossClicked="onCrossClickedOnRoleType"
                    @getUsersBasedOnselection="opeListSub(2)"
                    :selectedAll="'Role Type'"
                    :selectedOptions="selectedOpRole"
                    :showAllMailsFromParent="showAllMailsFromParent2"
                    :dummy="['salman21', 'salman22']"
                    :key="refreshRoleMulSelect"
                  />
                </div>
                <div v-if="selectedOne === 1 || selectedOne === 3">
                  <label for="from" class="text-xs fontWeight-600 mb-1"
                    >From:</label
                  >
                  <div class="flex mb-3">
                    <input
                      class="w-full"
                      type="email"
                      placeholder="info.apollodart@gmail.com"
                      id="from"
                      required
                      disabled
                      style="filter: opacity(0.6); font-weight: 600"
                    />
                    <button
                      type="button"
                      class="show_Cc_Btn text-xs h-full font-semibold ml-2 mt-2 px-1 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                      @click="showCC_Ip_Fun"
                    >
                      Cc
                    </button>
                  </div>
                </div>
                <label
                  v-if="showCC_Ip && selectedOne != 2"
                  for="cc"
                  class="text-xs fontWeight-600 mb-1"
                  >Cc:</label
                >
                <input
                  v-if="showCC_Ip && selectedOne != 2"
                  class="mb-3"
                  type="text"
                  placeholder="Cc"
                  id="cc"
                  v-model="cc"
                />
                <div v-if="true">
                  <label for="to" class="text-xs fontWeight-600 mb-1">{{
                    selectedOne !== 1 ? "Select Users:" : "To:"
                  }}</label>
                  <multi-select
                    class="users__"
                    :sendClicked="mail_Sending"
                    :allUsersSelected="allUsersSelected3"
                    :reqOptions="selectedUsers"
                    @getUsersBasedOnselection="opeListSub(3)"
                    @getingSelectedOptions="toUsers"
                    @onCrossClicked="onCrossClickedOnUser"
                    :selectedOptions="selectedOptouser"
                    :showAllMailsFromParent="showAllMailsFromParent3"
                    :selectedAll="selectedOne !== 1 ? 'Users' : 'Emails'"
                    :dummy="['salman31', 'salman32']"
                    :key="refreshuserMulSelect"
                  />
                </div>
                <div v-if="selectedOne === 1 || selectedOne === 3">
                  <label
                    for="customEmail"
                    class="text-xs fontWeight-600 mb-1 mt-3"
                    >Custom Email:</label
                  >
                  <input
                    @blur="customEmailsEntered"
                    multiple
                    type="email"
                    v-model="cusEmail"
                    placeholder="Enter comma( , ) Separated Mail Ids"
                    class="mb-3 w-full"
                    id="customEmail"
                  />
                </div>
                <div v-if="selectedOne === 2 || selectedOne === 3">
                  <label
                    for="mobileNumber"
                    class="text-xs fontWeight-600 mb-2 mt-3"
                    >Custom Mobile Number</label
                  >
                  <input
                    @change="customMobileNumEntered"
                    class="w-full mb-3"
                    type="text"
                    v-model="cusMbNum"
                    placeholder="Enter comma( , ) Separated Mobile Numbers"
                    id="mobileNumber"
                  />
                </div>
                <div class="mb-3" v-if="selectedOne === 1 || selectedOne === 3">
                  <label for="subject" class="text-xs fontWeight-600 mb-1 mt-3"
                    >Subject:</label
                  >
                  <input
                    class="w-full"
                    type="text"
                    id="subject"
                    placeholder="Subject"
                    v-model="subject"
                    required
                  />
                  <p v-if="selectedOne === 3" class="text-xs onlyEmail m-0">
                    Subject is only visible in Email not in SMS
                  </p>
                </div>
                <label for="textareaField" class="text-xs fontWeight-600 mb-1"
                  >Notes:</label
                >
                <textarea
                  v-model="DiscribeText"
                  ref="textareaField"
                  class="mb-3"
                  id="textareaField"
                  @change="textArea"
                  rows="10"
                  required
                ></textarea>
                <div
                  v-if="
                    selectedOpSub.length > 0 &&
                    selectedOptouser.length > 0 &&
                    DiscribeText &&
                    (selectedOne === 1 || selectedOne === 3 ? subject : true)
                  "
                >
                  <button
                    class="sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    type="submit"
                    :disabled="mail_Sending"
                  >
                    <span>{{ mail_Sending ? "Sending..." : "Send" }}</span>
                  </button>
                </div>
              </form>
            </div>
            <div v-if="selectedOne !== 2" style="width: 60%">
              <canvas id="image_to_show" class="hidden"></canvas>
              <p class="text-xs fontWeight-600 mb-1">Attachment:</p>
              <img
                v-if="
                  (capturedImage && uplodedFileType === '') ||
                  uplodedFileType === 'image/png'
                "
                :src="capturedImage"
                style="width: 100%"
              />
              <iframe
                v-if="capturedImage && uplodedFileType === 'application/pdf'"
                :src="capturedImage"
                style="width: 100%; height: 270px"
                frameborder="0"
              ></iframe>
              <div class="mt-4 flex justify-between items-center">
                <input
                  class="text-xs"
                  ref="Upld_Attchmnt"
                  type="file"
                  @change="Upload_Attachment"
                />
                <button
                  class="show_Cc_Btn text-xs p-1 rounded"
                  @click="RetateScreenshotClicked"
                >
                  {{
                    capturedImage === ""
                      ? "Take Screenshot"
                      : "Retake Screenshot"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- send mail modal code ends -->
      <!-- </div> -->
      <div
        v-if="showMask"
        class="mask fixed h-full w-full"
        style="top: 0; left: 0; z-index: 9999"
      ></div>
    </div>
  </div>
</template>
<script>
import MultiSelect from "../../Select/multiSelect.vue";
import api from "../../../api/services";

export default {
  components: {
    MultiSelect,
  },
  props: {
    CloseSupportPopUp: Function,
  },
  data() {
    return {
      isSupportScreenshotShowing: false,
      tousers: [],
      changeFile: "",
      uplodedFileType: "",
      allUsersSelected1: false,
      allUsersSelected2: false,
      allUsersSelected3: false,
      selectedOptouser: [],
      selectedOpSub: [],
      selectedOpRole: [],
      cusEmail: "",
      cusMbNum: "",
      cc: "",
      selectedUsersForMo: [],
      mail_Sending: false,
      customMobileNumArray: [],
      selectedUsersMobileNum: [],
      selectedSubE: [],
      selectedRT: [],
      tab: "",
      usersData: [],
      roleType: [],
      subEntities: [],
      selectedUsers: [],
      chooseRoleType: false,
      selectUserOf: false,
      selectTo: false,
      chooseSubEn: false,
      DiscribeText: "",
      subject: "",
      customMailsArray: [],
      allUsersSelected: false,
      showAllMailsFromParent1: false,
      showAllMailsFromParent2: false,
      showAllMailsFromParent3: false,
      selectedOption: [],
      selectedOptionOfSubEntities: [],
      reqOptions: [
        "salman",
        "syam",
        "sampath",
        "aslam",
        "kavitha",
        "asis",
        "prerana",
        "prajaktha",
        "akshay",
      ],
      selectedOne: 1,
      showCC_Ip: false,
      capturedImage: "",
      refreshSelectMenu: 756,
      refreshRoleMulSelect: 8765,
      refreshuserMulSelect: 9876,
      showMask: false,
    };
  },
  methods: {
    closehandler(from) {
      this.isSupportScreenshotShowing = false;
      // this.mail_Sending = false;
      // this.selectedOpSub=[];
      // this.allUsersSelected1=false;
      // this.refreshSelectMenu+=9;
      // this.allUsersSelected2=false;
      // this.selectedOpRole=[];
      // this.refreshRoleMulSelect+=9;
      // this.allUsersSelected3= false;
      // this.selectedOptouser = [];
      // this.refreshuserMulSelect+=9;
      this.CloseSupportPopUp();
      if (from && from == "fromElement") {
        this.mail_Sending = false;
        this.selectedOpSub = [];
        this.allUsersSelected1 = false;
        this.refreshSelectMenu += 9;
        this.allUsersSelected2 = false;
        this.selectedOpRole = [];
        this.refreshRoleMulSelect += 9;
        this.allUsersSelected3 = false;
        this.selectedOptouser = [];
        this.DiscribeText = "";
        this.roleType = [];
        this.selectedUsers = [];
        this.cc = "";
        this.subject = "";
        this.refreshuserMulSelect += 9;
        this.cusEmail = "";
        this.cusMbNum = "";
        this.customMailsArray = [];
        this.customMobileNumArray = [];
        this.selectedOne = 1;
        this.showCC_Ip = false;
      }
    },
    clickedOnCommunication(type) {
      this.selectedOne = type;
      this.showCC_Ip = true;
      this.cusEmail = "";
      this.cusMbNum = "";
      this.customMailsArray = [];
      this.customMobileNumArray = [];
    },
    RetateScreenshotClicked() {
      this.$refs.Upld_Attchmnt.value = null;
      this.isSupportScreenshotShowing = false;
      this.uplodedFileType = "";
      this.changeFile = "";
      this.closehandler();
      if (this.capturedImage != "") {
        this.$emit("retake_screen_shot");
      }
    },
    async takeScreenshotHandler() {
      console.log("take screen shot");
      try {
        await this.getDetailsOfDropdowns();
        this.showMask = true;
        const stream = await navigator.mediaDevices.getDisplayMedia({
          preferCurrentTab: true,
        });

        const videoTrack = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(videoTrack);
        const bitmap = await imageCapture.grabFrame();
        videoTrack.stop();

        this.showMask = false;
        const canvas = document.getElementById("canvasOfSupport");
        const ctx = canvas.getContext("2d");

        canvas.width = bitmap.width;
        canvas.height = bitmap.height;
        ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

        const image = canvas.toDataURL();
        this.capturedImage = image;
        this.isSupportScreenshotShowing = true;
        const res = await fetch(this.capturedImage);
        const buff = await res.arrayBuffer();
        // clone so we can rename, and put into array for easy processing
        const file = [
          new File([buff], `photo_${new Date()}.png`, {
            type: "image/png",
          }),
        ];
        this.changeFile = file;
      } catch (error) {
        this.isSupportScreenshotShowing = true;
        this.capturedImage = "";
      }
    },
    Upload_Attachment(event) {
      var input = event.target;
      console.log("file type", input.files[0]);
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.capturedImage = e.target.result;
        };
        this.changeFile = [input.files[0]];
        this.uplodedFileType = input.files[0].type;
        reader.readAsDataURL(input.files[0]);
      }
    },
    async getDetailsOfDropdowns() {
      let userDetails = this.$store.state.data.usersData;
      console.log("form admin sub Nav>>>>>", userDetails);
      this.subEntities = [...new Set(userDetails.subEntities)];
      this.usersData = userDetails.data;
    },
    textArea(event) {
      this.DiscribeText = event.target.value;
    },
    customMobileNumEntered(event) {
      let mbNumStr = event.target.value.replace(/\s/g, "");
      let s = /[a-zA-Z]/.test(mbNumStr);
      console.log("event of mobile nums", s);

      if (s) {
        console.log("event of mobile nums", mbNumStr);
        this.$toast.error("Please Enter Valid Mobile Number", {
          position: "top",
          duration: "2000",
        });
      } else {
        if (mbNumStr) {
          this.customMobileNumArray = mbNumStr
            .split(",")
            .filter((val) => val && val);
        } else {
          this.customMobileNumArray = [];
        }
      }
    },
    customEmailsEntered(event) {
      let customMail = event.target.value.replace(/\s/g, "");
      if (customMail) {
        this.customMailsArray = customMail
          .split(",")
          .filter((val) => val && val);
      } else {
        this.customMailsArray = [];
      }
    },
    onCrossClickedOnUser(selectedOpt, item, index) {
      if (item !== "all") {
        this.selectedOptouser.splice(index, 1);
      } else {
        this.selectedOptouser.splice(0, this.selectedOptouser.length);
        this.allUsersSelected3 = false;
      }
    },
    toUsers(selectedOp, data) {
      this.optionClicked(3, data);
      console.log("new user Data>>>>>>>>>>***********", data);
      this.tousers = data;
      // this.selectedOptouser = data
    },
    optionClicked(select, data) {
      // debugger
      console.log("check clicks", select, data);
      if (select === 1) {
        if (this.selectedOpSub.length !== this.subEntities.length) {
          if (data !== "all") {
            if (!this.selectedOpSub.includes(data)) {
              this.selectedOpSub.push(data);
              if (this.subEntities.length === this.selectedOpSub.length) {
                this.showAllMailsFromParent = !this.showAllMailsFromParent;
                this.allUsersSelected1 = true;
              }
            } else {
              let index = this.selectedOpSub.indexOf(data);
              this.selectedOpSub.splice(index, 1);
            }
          } else {
            if (this.selectedOpSub.length === this.subEntities.length) {
              this.selectedOpSub = [];
              this.allUsersSelected1 = false;
            } else {
              this.allUsersSelected1 = true;
              this.showAllMailsFromParent = false;
              this.selectedOpSub = [...this.subEntities];
            }
          }
        } else {
          if (this.allUsersSelected1 && data !== "all") {
            this.allUsersSelected1 = !this.allUsersSelected1;
            let index = this.selectedOpSub.indexOf(data);
            this.selectedOpSub.splice(index, 1);
          } else {
            this.allUsersSelected1 = false;
            this.showAllMailsFromParent = false;
            // this.selectedOpSub = [...this.subEntities]
            this.selectedOpSub = [];
          }
        }
      } else if (select === 2) {
        if (this.selectedOpRole.length !== this.roleType.length) {
          if (data !== "all") {
            if (!this.selectedOpRole.includes(data)) {
              this.selectedOpRole.push(data);
              if (this.roleType.length === this.selectedOpRole.length) {
                this.showAllMailsFromParent = !this.showAllMailsFromParent;
                this.allUsersSelected2 = true;
              }
            } else {
              let index = this.selectedOpRole.indexOf(data);
              this.selectedOpRole.splice(index, 1);
            }
          } else {
            this.allUsersSelected2 = true;
            this.showAllMailsFromParent = false;
            this.selectedOpRole = [...this.roleType];
            console.log("1 for all in RT", this.selectedOpRole);
          }
        } else {
          if (this.allUsersSelected2 && data !== "all") {
            this.allUsersSelected2 = !this.allUsersSelected2;
            let index = this.selectedOpRole.indexOf(data);
            this.selectedOpRole.splice(index, 1);
          } else {
            this.allUsersSelected2 = false;
            this.showAllMailsFromParent = false;
            this.selectedOpRole = [];
          }
        }
      } else if (select === 3) {
        if (this.selectedOptouser.length !== this.selectedUsers.length) {
          if (data !== "all") {
            if (!this.selectedOptouser.includes(data)) {
              this.selectedOptouser.push(data);
              if (this.selectedUsers.length === this.selectedOptouser.length) {
                this.showAllMailsFromParent = !this.showAllMailsFromParent;
                this.allUsersSelected3 = true;
              }
            } else {
              let index = this.selectedOptouser.indexOf(data);
              this.selectedOptouser.splice(index, 1);
            }
          } else {
            this.allUsersSelected3 = true;
            this.showAllMailsFromParent = false;
            this.selectedOptouser = [...this.selectedUsers];
          }
        } else {
          if (this.allUsersSelected3 && data !== "all") {
            this.allUsersSelected3 = !this.allUsersSelected3;
            let index = this.selectedOptouser.indexOf(data);
            this.selectedOptouser.splice(index, 1);
          } else {
            this.allUsersSelected3 = false;
            this.showAllMailsFromParent = false;
            this.selectedOptouser = [];
          }
        }
      }
    },
    showCC_Ip_Fun() {
      this.cc = "";
      this.showCC_Ip = !this.showCC_Ip;
    },
    onCrossClickedOnRoleType(selectedOpts, item, index) {
      console.log("checking:::::::::::::", selectedOpts);
      if (item !== "all") {
        this.selectedOpRole.splice(index, 1);
      } else {
        this.selectedOpRole.splice(0);
        this.allUsersSelected2 = false;
      }
      this.selectedUsers = [];

      let data = this.usersData;
      let sU = [];
      console.log("new users Data>>>>userData in selectedRoleType", data);
      for (let i of data.subentities) {
        if (this.selectedOpSub.includes(i.subEntityName)) {
          for (let j of i.usersRoles) {
            if (this.selectedOpRole.includes(j.roleName)) {
              sU = [...sU, ...j.users];
              console.log("new users Data>>>>>>>>>>>", j.users);
            }
          }
        }
      }
      let n = [];
      for (let m of sU) {
        console.log("new users Data>>>>entered,,,,,", sU);
        n.push(m.userName);
      }
      console.log("new users Data++++++++++++before set----", n);
      n = [...new Set(n)];
      console.log("new users Data++++++++++++after set----", n);
      this.selectedUsers = [...n];
      this.selectedUsersForMo = sU;
      this.clearSelectionOfToUsers(item);
    },
    clearSelectionOfToUsers(item) {
      if (this.allUsersSelected3) {
        this.allUsersSelected3 = false;
      }
      let users = [];
      for (let i of this.usersData.subentities) {
        if (this.selectedOpSub.includes(i.subEntityName)) {
          for (let k of i.usersRoles) {
            if (k.roleName === item) {
              for (let j of k.users) {
                users.push(j.userName);
              }
            }
          }
        }
      }
      let notMatchedUsers = [];
      for (let each of this.selectedOptouser) {
        if (!users.includes(each)) {
          notMatchedUsers.push(each);
        }
      }
      this.selectedOptouser = notMatchedUsers;
      if (item === "all") {
        this.selectedOptouser = [];
      }
    },
    selectedRoleTypes(roleTypes, selectedOp) {
      this.optionClicked(2, selectedOp);
      console.log("new users Data>>>>selectedRT", roleTypes);
      // this.selectedRT = roleTypes
      // this.selectedOpRole = roleTypes
      let data = this.usersData;
      let sU = [];
      console.log("new users Data>>>>userData in selectedRoleType", data);
      for (let i of data.subentities) {
        if (this.selectedOpSub.includes(i.subEntityName)) {
          for (let j of i.usersRoles) {
            if (this.selectedOpRole.includes(j.roleName)) {
              sU = [...sU, ...j.users];
              console.log("new users Data>>>>>>>>>>>", j.users);
            }
          }
        }
      }
      let n = [];
      for (let m of sU) {
        console.log("new users Data>>>>entered,,,,,", sU);
        n.push(m.userName);
      }
      console.log("new users Data++++++++++++before set----", n);
      n = [...new Set(n)];
      console.log("new users Data++++++++++++after set----", n);
      this.selectedUsers = [...n];
      this.selectedUsersForMo = sU;
      console.log("new users Data>>>>selectedUserts", this.selectedUsers);
    },
    onCrossClickedOnSubEntities(selectedOpts, item, index) {
      if (item !== "all") {
        this.selectedOpSub.splice(index, 1);
      } else {
        this.selectedOpSub.splice(0);
        this.allUsersSelected1 = false;
      }
      this.roleType = [];
      let rt = [];
      console.log("new checking>>>>", rt);
      for (let i of this.usersData.subentities) {
        if (this.selectedOpSub.includes(i.subEntityName)) {
          for (let k of i.usersRoles) {
            rt.push(k.roleName);
          }
        }
      }
      rt = [...new Set(rt)];
      console.log("new checking>>>>after", rt);
      this.roleType = [...rt];
      this.clearSelectedOptionsInRoleType(selectedOpts, item);
      this.selectedOptouser = [];
    },
    clearSelectedOptionsInRoleType(selectedOp, item) {
      if (this.allUsersSelected2) {
        this.allUsersSelected2 = false;
      }
      let roleNames = [];
      for (let i of this.usersData.subentities) {
        if (i.subEntityName === item) {
          for (let k of i.usersRoles) {
            roleNames.push(k.roleName);
          }
        }
      }
      let kk = [];
      for (let k of this.selectedOpRole) {
        if (!roleNames.includes(k)) {
          kk.push(k);
        }
      }
      this.selectedOpRole = kk;
      if (item === "all") {
        this.selectedOpRole = [];
      }
    },
    selectedSubentities(subEntities, data) {
      this.optionClicked(1, data);

      console.log("getting sub Entites>>>>", subEntities);

      console.log("new users Data>>>>>>>>>in seSubEn", this.usersData);
      // this.selectedSubE()
      let rt = [];
      console.log("new checking>>>>", rt);
      for (let i of this.usersData.subentities) {
        if (this.selectedOpSub.includes(i.subEntityName)) {
          for (let k of i.usersRoles) {
            rt.push(k.roleName);
          }
        }
      }
      rt = [...new Set(rt)];
      console.log("new checking>>>>after", rt);
      this.roleType = [...rt];
    },
    opeListSub(openList) {
      if (openList === 1) {
        this.showAllMailsFromParent1 = !this.showAllMailsFromParent1;
      } else if (openList === 2) {
        this.showAllMailsFromParent2 = !this.showAllMailsFromParent2;
      } else if (openList === 3) {
        this.showAllMailsFromParent3 = !this.showAllMailsFromParent3;
      }
    },
    async sendEmail() {
      let isValidated = this.validateGivenInfo();
      let messages_sent = false;
      if (isValidated) {
        this.mail_Sending = true;
        messages_sent = await this.postingTheInfo();
      }
      if (messages_sent) {
        this.mail_Sending = false;
        this.selectedOpSub = [];
        this.selectedOpRole = [];
        this.roleType = [];
        this.selectedUsers = [];
        this.selectedOptouser = [];
        this.allUsersSelected1 = false;
        this.allUsersSelected2 = false;
        this.allUsersSelected3 = false;
        this.cc = "";
        this.subject = "";
        this.DiscribeText = "";
        this.cusEmail = "";
        this.cusMbNum = "";
        this.customMailsArray = [];
        this.customMobileNumArray = [];
        if (this.$refs.Upld_Attchmnt?.value)
          this.$refs.Upld_Attchmnt.value = null;
        // if (this.$refs.textareaField.value) this.$refs.textareaField.value = null;
        this.capturedImage = "";
        this.changeFile = "";
        this.uplodedFileType = "";
      }
    },
    validateGivenInfo() {
      let isValidated = false;
      if (this.selectedOptouser.lenght === 0) {
        this.$toast.error("please select Users", {
          duration: 2000,
          position: "top",
        });
      } else if (this.selectedOpRole.lenght === 0) {
        this.$toast.error("please select Role Type", {
          duration: 2000,
          position: "top",
        });
      } else if (this.selectedOpSub.length === 0) {
        this.$toast.error("please select Sub-Entity", {
          duration: 2000,
          position: "top",
        });
      } else {
        isValidated = true;
      }
      return isValidated;
    },
    outSideClicked(event) {
      let popUpSub = document.querySelector(".sub__");
      let popUpRole = document.querySelector(".role__");
      let popUpUser = document.querySelector(".users__");
      if (this.showAllMailsFromParent1) {
        if (!popUpSub.contains(event.target)) {
          this.showAllMailsFromParent1 = false;
        }
      } else if (this.showAllMailsFromParent2) {
        if (!popUpRole.contains(event.target)) {
          this.showAllMailsFromParent2 = false;
        }
      } else if (this.showAllMailsFromParent3) {
        if (!popUpUser.contains(event.target)) {
          this.showAllMailsFromParent3 = false;
        }
      }
    },
    async postingTheInfo() {
      console.log("selected users for mobile", this.selectedUsersForMo);
      let fromMail = JSON.parse(localStorage.getItem("userName"));
      let moN = [];
      for (let i of this.selectedUsersForMo) {
        if (this.selectedOptouser.includes(i.userName)) {
          moN.push(i.mobileNumber);
        }
      }
      moN = [...moN, ...this.customMobileNumArray];

      let formData = new FormData();
      formData.append("recipients", [
        ...this.selectedOptouser,
        ...this.customMailsArray,
      ]);
      formData.append("messageBody", this.DiscribeText);
      formData.append("cc", [this.cc]);
      formData.append("subject", this.subject);
      formData.append("multipart", true);
      this.capturedImage !== "" && formData.append("files", this.changeFile[0]);

      let isEmailSent = false;
      let isSmsSent = false;

      if (this.selectedOne == 1 || this.selectedOne === 3) {
        isSmsSent = true;
        try {
          let response = await api.SupportServices.sendingEmailToselectedUsers(
            formData
          );
          if (response.status === 200) {
            isEmailSent = true;
            this.$toast.success("Mail sent successfully", {
              duration: 2000,
              position: "top",
            });
            this.mail_Sending = false;
            this.selectedOpSub = [];
            this.selectedOpRole = [];
            this.roleType = [];
            this.selectedOptouser = [];
            this.cc = "";
            this.subject = "";

            this.cusEmail = "";
            this.cusMbNum = "";
          } else {
            console.error(" error in send email : ", error.response);
            this.mail_Sending = false;
            this.$toast.error("Email not Sent", {
              duration: 2000,
              position: "top",
            });
          }
        } catch (error) {
          this.mail_Sending = false;
          this.$toast.error("Email not Sent", {
            duration: 2000,
            position: "top",
          });
        }
      }
      if (this.selectedOne == 2 || this.selectedOne === 3) {
        isEmailSent = true;
        isSmsSent = false;
        try {
          let obj = {
            recipients: moN,
            messageBody: this.DiscribeText,
          };
          let response2 = await api.SupportServices.sendingSmsToSelectedUsers(
            obj
          );
          if (response2.status === 200) {
            isSmsSent = true;
            this.mail_Sending = false;
            this.$toast.success(response2.data.message, {
              duration: 2000,
              position: "top",
            });
          } else {
            this.$toast.error("Message not sent", {
              duration: 2000,
              position: "top",
            });
            this.mail_Sending = false;
          }
        } catch (error) {
          console.error(" error in send sms : ", error.response);
          this.$toast.error(error.response?.data?.message, {
            duration: 2000,
            position: "top",
          });
          this.mail_Sending = false;
        }
      }

      return isEmailSent && isSmsSent;
    },
  },
  mounted() {
    this.getDetailsOfDropdowns();
  },
  expose: ["takeScreenshotHandler"],
};
</script>
<style scoped>
.activeCommuni {
  border-bottom: 2px solid var(--activeTextColor);
  border-radius: 0;
  color: var(--activeTextColor);
  font-size: 15px;
  transition: all 0.01s ease-in;
}
</style>
